let browseInfo = {};

function getDeviceInfo(o) {
  let ua = o || navigator.userAgent;
  let liulanqi = '';
  let banben = '';
  let os = '';
  let equipment = '';
  let isEdge = ua.match(/(Edge)\/([\d.]+)/i);
  let isSafari = ua.match(/Version\/([\d.]+)\s+?(Safari)/i);
  let isChrome = ua.match(/(Chrome)\/([\d.]+)/i);
  let isFirefox = ua.match(/(Firefox)\/([\d.]+)/i);
  let isOpera = ua.match(/(OPR|OPERA).+?([\d.]+)/i);
  let isIE = ua.indexOf('Trident') > -1 || ua.toLocaleLowerCase().indexOf('msie') > -1;
  let isBrowser = ua.match(/(\w+Browser)[^\d]+?([\d.]+)/i) || ua.match(/\d\s+?([^\d]+)([\d.]+)$/i);
  let isAndroid = ua.match(/(Android[\s\d.]+);.+?([^;]+)Build/i);
  let isWe = ua.match(/MicroMessenger.+?([\d.]+)/i);
  let isIPhones = ua.match(/CPU([\s\w\d_]+)like/i);
  let isIPad = ua.match(/iPad/i) ? 'iPad' : 'iPhone';
  let isVersion = ua.match(/Version\/([\d.]+)/i);
  let isMobile = 0;


  // 如果是移动端
  if (!!ua.match(/AppleWebKit.*Mobile.*/)) {
    isMobile = 1;
    if (isWe) {
      liulanqi = '微信';
      banben = isWe[1];
    } else {
      if (isBrowser) {
        liulanqi = isBrowser[1];
        banben = isBrowser[2];
        if (liulanqi.indexOf('Browser') === -1 && isVersion) {
          banben = isVersion[1];
        }
      } else {
        liulanqi = '手持设备浏览器';
        banben = '未知';
      }
    }
    if (isAndroid) {
      os = isAndroid[1];
      equipment = isAndroid[2];
    } else {
      if (isIPhones) {
        os = isIPhones[1];
      } else {
        os = isIPad;
      }
      equipment = isIPad;
    }
  } else {
    if (isSafari) {
      liulanqi = isSafari[2];
      banben = isSafari[1];
    }
    if (isChrome) {
      liulanqi = isChrome[1];
      banben = isChrome[2];
    }
    if (isFirefox) {
      liulanqi = isFirefox[1];
      banben = isFirefox[2];
    }
    if (isEdge) {
      liulanqi = isEdge[1];
      banben = isEdge[2];
    }
    if (isOpera) {
      liulanqi = 'OPERA';
      banben = isOpera[2];
    }

    if (isIE) {
      liulanqi = 'IE';
      let isIEV01 = ua.match(/(MSIE).+?(\d+)/i);
      let isIEV02 = ua.match(/(rv).+?(\d+)/i);
      if (isIEV01) {
        banben = isIEV01[2];
      }
      if (isIEV02) {
        banben = isIEV02[2];
      }
    }
    os = ua.match(/windows/i) ? 'Windows' : 'Mac OS';
    equipment = '电脑端';
  }

  browseInfo.userAgent = ua;
  browseInfo.browser = liulanqi;
  browseInfo.version = banben;
  browseInfo.os = os;
  browseInfo.equipment = equipment;
  browseInfo.resolution = window.screen.width + 'X' + window.screen.height;
  browseInfo.referenceUrl = document.referrer || '';
  browseInfo.referenceTitle = '';
  browseInfo.url = window.location.href;
  browseInfo.title = document.title;
  browseInfo.lib = 'JS';
  browseInfo.lib_version = '1.0'
}
getDeviceInfo();
export {browseInfo};