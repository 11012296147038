import {post} from './xhr.js'
import {publicPara} from './config'
function login (obj) {

}

function register (obj) {

}
//绑定联系人
let bind = function (isKeepCookieId) {
  const obj = {formData:{},isKeepCookieId:!!isKeepCookieId}
  post(obj,'/reporting/bind');
}

let unbind = function (isKeepCookieId) {
  const obj = {formData:{},isKeepCookieId:!!isKeepCookieId}
  post(obj,'/reporting/unbind');
}

//创建联系人
let setProfile = function (obj) {
  ///兼容处理
  if(!obj.formData){
    obj = {
      formData:obj
    }
  }
  post(obj,'/reporting/create')
}

//创建线索
let setLeads = function (obj) {
  ///兼容处理
  if(!obj.leads){
    obj = {
      leads:obj
    }
  }
  post(obj,'/reporting/lead')
}


let track = function (event,properties) {
  properties = properties || {};
  let obj ={
    event,
    properties: Object.assign(properties,publicPara),
  }
  post(obj,'/reporting/track')
}

export {setProfile,bind,unbind,track,setLeads}


