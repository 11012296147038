let bin2Hex = function(str) {
  var re = /[\u4E00-\u9FA5]/;
  var ar = [];
  for (var i = 0; i < str.length; i++) {
    var a = '';
    if (re.test(str.charAt(i))) { // 中文
      a = encodeURI(str.charAt(i)).replace(/%/g, '');
    } else {
      a = str.charCodeAt(i).toString(16);
    }
    ar.push(a);
  }
  str = ar.join("");
  return str;
}

let getId = function () {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');
  var txt = 'http://www.sinobase.com/';
  ctx.textBaseline = "top";
  ctx.font = "14px 'Arial'";
  ctx.textBaseline = "sinobase";
  ctx.fillStyle = "#f60";
  ctx.fillRect(125,1,62,20);
  ctx.fillStyle = "#069";
  ctx.fillText(txt, 2, 15);
  ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
  ctx.fillText(txt, 4, 17);
  var b64 = canvas.toDataURL().replace("data:image/png;base64,","");
  var bin = atob(b64);
  var crc = bin2Hex(bin.slice(-16,-12));
  return 'bfp' + crc;
}

export {getId}