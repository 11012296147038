//sdk配置

let config = {
  server_url: '',
  show_log: false,
  auto_track: true,
  heatmap: {
    clickmap: false,
    track_attr: 'data-sk-click'
  }
}

//公共事件属性 track时需要合并
let publicPara = {

}

let dataSource = '';

let isSandBox = 0;


//当前用户属性
let profile = {
  cookieId: '',
  member: {},
  wechat: {
    openId: '',
    weChatId: '',
    unionId: ''
  },
  miniProgram: {
    programType: '',
    programUserId: '',
    unionId: '',
    programId: ''
  }
};


export {config,publicPara,profile,dataSource,isSandBox};
