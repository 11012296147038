import {setPara, registerPublicProperties,init,setSource,setSandBox} from './core/init'
import  {bind, setProfile, track, unbind, setLeads} from './core/api'
import {setIdentities,getIdentities,clearIdentities} from './core/user'



export {
  setPara,
  registerPublicProperties,
  bind,
  unbind ,
  setIdentities,
  getIdentities,
  init,
  setProfile,
  setSource,
  track,
  setLeads,
  clearIdentities,
  setSandBox
}


let smarketLib = {
  setSource,
  setPara,
  registerPublicProperties,
  bind,
  unbind,
  setIdentities,
  getIdentities,
  init,
  track,
  setLeads,
  clearIdentities,
  setProfile,
  setSandBox
}

export default smarketLib;