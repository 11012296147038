let channel = {};
let getQueryParams = function() {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const hashParams = new URLSearchParams(url.hash.slice(1));

  let searchObj = {};
  let hashObj = {};

  for (const [key, value] of searchParams.entries()) {
    searchObj[key] = value;
  }

  for (const [key, value] of hashParams.entries()) {
    if(key.indexOf('/')<0 || key.indexOf('?') > -1) {
      hashObj[key.indexOf('?') > -1 ? key.split('?')[1] : key] = value;
    }
  }

  return { ...searchObj, ...hashObj };
}
channel = getQueryParams();
console.log(channel);
export {channel,getQueryParams};