import {profile} from "./config";
import {setCookie,getCookie,removeCookie} from "./cookie";

let setIdentities = function (key,obj) {
  if (profile[key] || key == 'cookieId') {
    if(typeof obj == 'object') {
      Object.assign(profile[key], obj);
    }else {
      profile[key] = obj;
    }
  }
  setProfileCookie();
}

let setMemberProfile = function (obj) {
  setIdentities('member',obj);
}


let setIdentitiesOnce = function (key,obj) {
  for (let key1 in obj) {
      if (!profile[key][key1]) {
        profile[key][key1] = obj[key1];
      }

  }
  setProfileCookie()
}
let setMemberProfileOnce = function (obj) {
  setIdentitiesOnce('member',obj);
}

let getIdentities = function () {
  return JSON.parse(JSON.stringify(profile));
}

let unIdentities = function (key,obj) {
  if (profile[key]) {
    if (typeof obj == 'object') {
      for (let i = 0; i < obj.length; i++) {
        if (profile[key][obj[i]]) {
          profile[key][obj[i]] = null;
        }
      }
    } else {
      if (profile[key][obj]) {
        profile[key][obj] = null;
        delete profile[key][obj];
      }
    }
  }
}

let setProfileCookie = function () {
  setCookie('smarketMember' , profile);
}

let getProfileCookie = function () {
  return getCookie('smarketMember');
}

let clearIdentities = function () {
  removeCookie('smarketMember');
  profile = {
    cookieId: '',
    member: {},
    wechat: {
      openId: '',
      weChatId: '',
      unionId: ''
    },
    miniProgram: {
      programType: '',
      programUserId: '',
      unionId: '',
      programId: ''
    }
  };
  setCookie('smarketMember' , profile);
}


export {setIdentities,getIdentities,setProfileCookie,getProfileCookie,clearIdentities}