import {config,dataSource,isSandBox} from './config'
import {browseInfo} from "./interactive";
import {profile} from "./config";
import {getQueryParams} from './channel'
function Ajax(type, url, data, success, failed){
  // 创建ajax对象
  var xhr = null;
  if(window.XMLHttpRequest){
    xhr = new XMLHttpRequest();
  } else {
    xhr = new ActiveXObject('Microsoft.XMLHTTP')
  }

  var type = type.toUpperCase();
  // 用于清除缓存
  var random = Math.random();
  var getData ='';
  if(typeof data == 'object'){
    var str = '';
    for(var key in data){
      str += key+'='+data[key]+'&';
    }
    getData = str.replace(/&$/, '');
  }

  if(type == 'GET'){
    if(getData){
      xhr.open('GET', url + '?' + getData, true);
    } else {
      xhr.open('GET', url + '?t=' + random, true);
    }
    xhr.send();

  } else if(type == 'POST'){
    xhr.open('POST', url, true);
    // 如果需要像 html 表单那样 POST 数据，请使用 setRequestHeader() 来添加 http 头。
    xhr.setRequestHeader("Content-type", "application/json");
    xhr.setRequestHeader("Accept", "application/json");
    data.browseInfo = browseInfo;
    xhr.send(JSON.stringify(data));
  }

  const show_log = config.show_log;
  if(show_log) {
    console.log('############请求数据begin##############')
    console.log(data);
    try {
      console.log(JSON.stringify(data,null,2));
    }
    catch (e){

    }
    console.log('############请求数据end##############')
  }
  // 处理返回数据
  xhr.onreadystatechange = function(){

    if(xhr.readyState == 4){
      if(show_log) {
        if(xhr.responseText) {
          console.log('############响应数据begin##############')

          console.log(JSON.stringify(JSON.parse(xhr.responseText), null, 2));
          console.log('############响应数据end##############')
        }
      }
      if(xhr.status == 200){
        if(success && typeof success == 'function'){
          success(xhr.responseText);
        }

      } else {
        if(failed && typeof failed == 'function'){
          failed(xhr.status);
        }
      }
    }
  }
}

function post(data,cmd, success, failed) {
  const url = config.server_url + cmd;
  data.profile = profile;
  data.utms = getQueryParams();
  if(!data.dataSource||data.dataSource == ''){
    data.dataSource = dataSource;
  }
  if (isSandBox) {
    data.isSandBox = isSandBox;
  }
  if(data.isKeepCookieId){
    data = {...data};
    delete data.profile.cookieId;
  }else{
    if(!data.profile.cookieId){
      console.log('Please run \'init\' function first');
      return;
    }
  }
  Ajax('post',url,data, success, failed);

}
export {post}