function setCookie(key, val, time) {
  //存值
  //time 过期时间 单位毫秒

  if(typeof val == 'object'){
    val = JSON.stringify(val);
  }
  let date;
  if(time) {
    date = new Date(new Date().getTime() + time);
  }else{
    //参考神策 一年有效
    date = new Date(new Date().getTime() + 31536000000)
  }
  document.cookie = `${key}=${val};expires=${date};path=/`
}

//getCookie()  获取数据

function getCookie(key) {
  var arr = document.cookie.split('; ')
  for (var i = 0; i < arr.length; i++) {
    var newArr = arr[i].split('=');
    if (newArr[0] == key) {
      return JSON.parse(newArr[1]);
    }
  }
}

//removeCookie() 删除cookie
function removeCookie(key) {
  //设置 过期时间
  setCookie(key, null, -1);
}

//clearCookie() 清空cookie
function clearCookie() {
  var arr = document.cookie.split('; ')
  for (var i = 0; i < arr.length; i++) {
    var newArr = arr[i].split("=");
    this.setCookie(newArr[0], '', -1)
  }
}
export {setCookie,getCookie,removeCookie,clearCookie}